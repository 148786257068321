<template>
      
  <section class="dashboard">
    
    <router-link class="rounded-button float-right bg-gradient-primary" :to="link('/create')">+</router-link>
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> {{$options.label }}</h3>
        
        <!--
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Vista general<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        -->
    </div>
 
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listando {{$options.label}}</h4>
            <div class="table-responsive">


              <table class="table">
                <thead>
                  <tr>
                    <th><input type="checkbox"></th>
                    <th> ID </th>
                    <th> Desarrollo </th>
                    <th> Nombre </th>
                    <th> Cantidad </th>
                    <th> Categoría </th>
                    <th> Fecha límite </th>
                    <th> Estado </th>
                    <th> Acciones </th>
                  </tr>
                </thead>
                <tbody>

                  <tr v-if="listing">
                    <td colspan="10">
                      <b-skeleton-table
                        :rows="3"
                        :columns="4"
                        :table-props="{ bordered: true, striped: true }"
                        
                      ></b-skeleton-table>
                    </td>
                  </tr>


                  <tr v-for="(item, index) in expenseItems" :key="index">
                    <td><input type="checkbox"></td>
                    <td>{{item.id}}</td>
                    <td>{{ item.development.name }}</td>
                    <td>{{ item.concept }}</td>
                    <td>${{ $numberFormat(item.amount) }}</td>
                    <td>{{ item.budget.name }}</td>
                    <td>{{ $customDate(item.due_date.split(" ")[0]) }}</td>
                    
                    <td>
                      <statusSelector :key="counter" :editable="item.status!='paid'" :item_id="item.id" :route="'expenses'" :selected="item.status"
                      :state="[{value:'pending',label:'Pendiente',statusColor:'yellow'},
                                {value:'approved',label:'Aprobado',statusColor:'green'},
                                {value:'refused',label:'Rechazado',statusColor:'red'},
                                {value:'paid',label:'Pagado',statusColor:'blue',hidden:true},
                                ]"></statusSelector>
                    </td>
                    <td>
                      <a title="Pagar" v-if="item.status!='paid'" class="action-link" @click="payMe(item.id,index)"><i class="mdi mdi-cash-check"></i></a>
                      <a title="Cancelar" v-if="item.status=='paid'" class="action-link" @click="cancelMe(item.id,index)"><i class="mdi mdi-cash-remove"></i></a>
                      <router-link v-if="item.status!='paid'" title="Editar" class="action-link" :to="link('/update/'+item.id)"><i class="mdi mdi-square-edit-outline"></i></router-link>
                      <a title="Eliminar" class="action-link" @click="deleteMe(item.id,index)"><i class="mdi mdi-delete-outline"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>



    <b-modal ref="modalPayment" id="form-modal" size="sm" title="Realizar pago" :hide-footer="true">
        
      <form @submit.prevent="payExpense">
        <div class="form-group">
          <label>Elige la forma de pago</label>
          <fundSelector :required="true" @onselect="onFundSelect"/>
        </div>

        <submit-button :loading_flag="loading" :loading_label="'Cargando...'" :label="'Registrar pago'"></submit-button>
    </form>

    </b-modal>

  </section>
</template>

<script>

import api from '@/util/api.js';
import statusSelector from '@/components/statusSelector';
import fundSelector from '@/components/fundSelector';
import submitButton from '@/components/ui-feature/submit-button';


export default {
  routeName:'expenses',
  label:'Gastos',
  name: 'listExpenses',

  components: {
    statusSelector,
    fundSelector,
    submitButton
  },
  data() {
    return {
      items:[],
      listing:false,
      loading:false,
      fund_id:0,
      expense_id:0,
      counter:0
     
    };
  },

  computed:{

    expenseItems(){

      return this.items;

    }

  },


  methods: {
    getItems() {

      this.listing=true;
      
      api.get(this.me('?expand=development,fund,budget')).then(response => {
        this.items = response.data;

        
      }).catch(error => {
        console.log(error);
      }).finally(()=>{

        this.listing=false;

      });
    },


    payMe(id){

      this.expense_id=id;
      this.$refs.modalPayment.show();

    },

    onFundSelect(v){
      this.fund_id=v;

    },

    payExpense(){


      this.loading=true;
      api.patch(this.me('/'+this.expense_id),{
        fund_id:this.fund_id,
        status:'paid'
      }).then(response=>{

        const id=response.data.id;
        
        const index=this.items.findIndex(e=>{
          return e.id==id
        });
        this.items[index].status='paid';
        this.counter++;
        this.$refs.modalPayment.hide();
        this.$notify({message:'Pago registrado',type:'success'});


        }).catch(error=>{

        console.log(error);
        this.$notify({message:'No se pudo registrar el pago',type:'error'});

        }).finally(()=>{

          this.loading=false;

        });
    },


    cancelMe(id,index){

        if(confirm('¿Seguro que desea cancelar el pago?')){


          this.loading=true;
          api.patch(this.me('/'+id),{
            fund_id:null,
            status:'pending'
          }).then(response=>{
            console.log(response);
            this.items[index].status='pending';
            this.counter++;
            this.$notify({message:'Pago cancelado',type:'success'});


            }).catch(error=>{

            console.log(error);
            this.$notify({message:'No se pudo cancelar el pago',type:'error'});

            }).finally(()=>{

              this.loading=false;

            });


        }


    },

    deleteMe(id,index){





      if(confirm('¿Desea eliminar el elemento?')) {

              api.delete(this.me(id)).then(()=>{

                this.items.splice(index,1);
                this.$forceUpdate();
                
              
              }).catch(error=>{

                console.log(error);
                
              });

        }

      }
          

      
  },

  mounted(){

    
    this.getItems();
  }

}



</script>


