<template>

    <select v-bind:required="required" class="form-control" @change="onChange">
        <option value="">Selecciona</option>
        <option v-for="(item,index) in items" :key="index" :value="item.id">{{ item.name }}</option>
    </select>

</template>

<script>

import api from '@/util/api.js';

export default{


    name:'selectFund',
    routeName:'funds',
    data(){
        return{

            items:[]
        }

    },

    props:{

        required:{
            type:Boolean,
            default:false
        }

    },
    
    methods:{
        
        onChange(event){
            this.$emit('onselect',event.target.value);

        },
        loadItems(){
            
            api.get(this.me()).then(response=>{

                this.items=response.data;

            });
            
        }

    },

    mounted(){

        this.loadItems();

    }



}

</script>